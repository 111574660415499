import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/lotties/loadingAnimation.json';
import loadingText from '../../assets/lotties/loadingText';
import Background from '../../assets/bgImages/userLoadingBg.png';
import '../../styles/globalStyles.css';
function ClientLoading({isValid}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: -2,
        height: '100vh',
      }}>
      <div
        style={{
          width: '100%',
          position: 'absolute',
          height: '100vh',
          backgroundImage: `url(${Background})`, // Add this line
          backgroundSize: 'cover', // Optional: Adjust how the background image is displayed
          backgroundPosition: 'center', // Optional: Adjust the position of the background image
          filter: 'blur(5px)',
        }}
      />
      <div
        style={{
          width: '100%',
          position: 'absolute',
          height: '100vh',
          backgroundColor: 'black',
          opacity: 0.3,
        }}
      />
      <div style={{width: 400}}>
        <Lottie animationData={loadingAnimation} loop={true} />
      </div>
      <div style={{width: 300, zIndex: 66, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        {isValid ?
           <span className="title_loading">{isValid}</span>
          : 
          <Lottie animationData={loadingText} loop={true} />
          }
        
      </div>
    </div>
  );
}

export default ClientLoading;
