import React from 'react';
import { BrowserRouter} from "react-router-dom";
import RouterComponent from './components/RouterComponent';


function App() {

  return (
    <BrowserRouter>
      <RouterComponent />
    </BrowserRouter>
  );
}

export default App;
