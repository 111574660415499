import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import moment from 'moment';
import '../../styles/globalStyles.css';
import AdminPanelCreate from './AdminPanelCreate';
import AdminPanelLists from './AdminPanelLists';
import AdminPanelEdit from './AdminPanelEdit';
import HeaderComponent from '../HeaderComponent';


const containerStyle = {
  width: '100%',
  height: '100vh',
};

const center = {
  lat: 37.0902, // Latitude of the center of the US
  lng: -95.7129, // Longitude of the center of the US
};

const API_KEY = 'AIzaSyB_AkgpgYpDNiUyGWoQApq4NgYS44VKXRY';
const LIBS = ['geometry', 'places', 'routes', 'drawing', 'elevation'];

function AdminPage() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
    libraries: LIBS,
  });

  const [map, setMap] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [currentPositions, setCurrentPosition] = useState();
  const [selectedPlace, setSelectedPlace] = useState();
  const [markers, setMarkers] = useState([]); // Track markers state
  const [overlays, setOverlays] = useState([]); // Track overlays state
  const [panel, setPanel] = useState('all');
  const interval = ((selectedPlace?.distance / selectedPlace?.speed) * 60 * 60) //594

 const date1 = moment(selectedPlace?.futureDate, 'dddd, MMMM Do YYYY, h:mm:ss A');
 const date2 = moment();

 const differenceInSeconds = date1.diff(date2, 'seconds');
 const percentage = (100 - (differenceInSeconds / interval) * 100);

 useEffect(() => {
  if (selectedPlace && isLoaded) {
    const origin = selectedPlace?.coordinatesFrom;
    const destination = selectedPlace?.coordinatesTo;
    // Clear old markers and overlays
    if (markers.length > 0) {
      markers.forEach((marker) => marker.setMap(null));
      setMarkers([]);
    }
    if (overlays.length > 0) {
      overlays.forEach((overlay) => overlay.setMap(null));
      setOverlays([]);
    }

    // Recalculate and animate with the new selectedPlace
    calculateAndDisplayRoute(origin, destination);
  }
  return () => {
    // Cleanup function to clear markers and overlays when component unmounts
    if (markers.length > 0) {
      markers.forEach((marker) => marker.setMap(null));
    }
    if (overlays.length > 0) {
      markers.forEach((marker) => marker.setMap(null));
    }
  };
}, [selectedPlace, isLoaded]);

  const onLoad = React.useCallback((mapInstance) => {
    setMap(mapInstance);
    mapInstance.setZoom(10);

    // Initialize Directions Service and Renderer
    const directionsServiceInstance = new window.google.maps.DirectionsService();
    const directionsRendererInstance = new window.google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: '#4285F4', // Set your desired color here
        strokeOpacity: 0, // Set the transparency level (0.0 to 1.0)
        strokeWeight: 0,  // Set the thickness of the route line
      },
      // suppressMarkers: true, // Suppress default markers if needed
    });

    directionsRendererInstance.setMap(mapInstance);
    setDirectionsService(directionsServiceInstance);
    setDirectionsRenderer(directionsRendererInstance);
  }, []);

  const calculateAndDisplayRoute = (origin, destination) => {
    if (directionsService && directionsRenderer) {
      // Clear old markers and overlays before adding new ones
      if (markers.length > 0) {
        markers.forEach((marker) => {
          marker.setMap(null); // Remove the marker from the map
        });
        setMarkers([]); // Clear the markers array
      }
  
      if (overlays.length > 0) {
        overlays.forEach((overlay) => {
          overlay.setMap(null); // Remove the overlay from the map
        });
        setOverlays([]); // Clear the overlays array
      }
      // Calculate the new route
      directionsService
        .route({
          origin: { 
            location: new window.google.maps.LatLng(origin.lat, origin.lng)
          },
          destination: { 
            location: new window.google.maps.LatLng(destination.lat, destination.lng)
          },
          // origin: { query: origin },
          // destination: { query: destination },
          travelMode: 'DRIVING',
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
  
          // Extract the path (precise path from polyline encoding)
          const legs = response.routes[0].legs;
          const precisePath = [];
          legs.forEach((leg) => {
            leg.steps.forEach((step) => {
              const segment = window.google.maps.geometry.encoding.decodePath(step.polyline.points);
              precisePath.push(...segment);
            });
          });

          // Create the new marker
          const newMarker = new window.google.maps.Marker({
            path: precisePath,
            map: map,
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 10,
              strokeColor: 'black',
              fillColor: 'white',
              fillOpacity: 1,
              strokeWeight: 5,
            },
          });
 
          // Store the new marker in the state
          setMarkers((prevMarkers) => [...prevMarkers, newMarker]);
  
          // Create the new overlay
          const newOverlay = new window.google.maps.OverlayView();
          newOverlay.onAdd = function () {
            const div = document.createElement('div');
            div.style.position = 'absolute';
            div.style.opacity = 0.7;
            div.style.backgroundColor = !selectedPlace?.isPaused ? 'black' : 'red';
            div.style.color = 'white';
            div.style.padding = '5px 10px';
            div.style.borderRadius = '5px';
            div.style.fontSize = '14px';
            div.style.zIndex = '9999';

            div.innerText = !selectedPlace?.isPaused ?selectedPlace.title : selectedPlace?.title + ' ' + 'Stopped';
            this.div = div;
  
            const panes = this.getPanes();
            panes.overlayMouseTarget.appendChild(div);
          };
  
          newOverlay.draw = function () {
            if (this.div) {
              const projection = this.getProjection();
              const pixelPosition = projection.fromLatLngToDivPixel(newMarker.getPosition());
              if (pixelPosition) {
                this.div.style.left = `${pixelPosition.x}px`;
                this.div.style.top = `${pixelPosition.y - 30}px`; // Position above the marker
              }
            }
          };
  
          newOverlay.onRemove = function () {
            if (this.div) {
              this.div.remove();
              this.div = null;
            }
          };
  
          newOverlay.setMap(map);
          setOverlays((prevOverlays) => [...prevOverlays, newOverlay]);
          animateCircleAndMarkerWithOverlay(precisePath, newMarker, newOverlay, selectedPlace);
        })
        .catch((e) => window.alert('Directions request failed due to ' + e));
    }
  };

  const animateCircleAndMarkerWithOverlay = (precisePath, marker, overlay) => {
    let index = Math.floor((percentage / 100) * precisePath.length); // Calculate starting index based on percentage
    const currentTime = moment(); // Using moment.js to get current time
    const futureTime = moment(selectedPlace?.futureDate, "dddd, MMMM Do YYYY, h:mm:ss A"); // Parse futureDate
    const totalDuration = futureTime.diff(currentTime, 'seconds'); // Total duration in seconds
    
    // Calculate the number of steps to take based on the precisePath
    const totalSteps = precisePath.length;
    
    // Calculate the interval between each step (in milliseconds)
    const stepDuration = (totalDuration / totalSteps) * 1000; // Convert to milliseconds
    
    let currentPosition = null; // Declare currentPosition outside of the interval
    
    // Ensure that the first position is set even if the animation doesn't start
    if (precisePath.length > 0) {
      currentPosition = precisePath[index];
    }

    // Store the interval ID for clearing later
    const intervalId = window.setInterval(() => {
      if (index < precisePath.length && !selectedPlace?.isPaused) {
        // Continue animation
        currentPosition = precisePath[index]; // Update the current position
        marker.setPosition(currentPosition);
        overlay.draw();
        index++;
      } else if (selectedPlace?.isPaused && currentPosition) {
        // Pause the animation and log the current position
        window.clearInterval(intervalId);
        marker.setPosition(currentPosition);
        setCurrentPosition({lat: currentPosition.lat(), lng: currentPosition.lng()})
      } else {
        // Stop animation when the end of the path is reached or selectedPlace is undefined
        const latLng = new window.google.maps.LatLng(selectedPlace?.coordinatesFrom.lat, selectedPlace?.coordinatesFrom.lng);
        marker.setPosition(latLng);
        window.clearInterval(intervalId);
      }
    }, stepDuration);
  };

  return isLoaded ? (
    <>
      <HeaderComponent title={selectedPlace?.bannerTitle} />
      <GoogleMap
       key={+selectedPlace?.id || ''}  
        id="google-map-script"
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={onLoad}
        zoom={10}
        options={{
          zoomControl: true,
          streetViewControl: true,
          mapTypeControl: true,
          fullscreenControl: true,
        }}
      />
      <>

        <div className="black_bg_op_admin_panel">
         <div style={{padding: 15, display: 'flex', flexDirection: 'column'}}>
           {panel === 'all' ? (
             <AdminPanelLists 
             start={currentPositions ? currentPositions : selectedPlace?.coordinatesFrom}
             end={selectedPlace?.coordinatesTo}
             setSelectedPlace={setSelectedPlace}
            selectedPlace={selectedPlace}
             isFinish={percentage < 100 ? false : true}
             currentPosition={currentPositions}
            speed={selectedPlace?.speed}
              id={selectedPlace?.id}
              isPaused={selectedPlace?.isPaused}
              percentage={percentage}
              setCurrentPosition={setCurrentPosition}
              setPanel={setPanel}
                />
           ) : panel === 'create' ?(
             <AdminPanelCreate setPanel={setPanel} key={API_KEY} libs={LIBS} />
           )
          :
          <AdminPanelEdit setPanel={setPanel} key={API_KEY} libs={LIBS} selectedPlace={selectedPlace}/>
          }
         </div>
         <div
           style={{
             display: 'flex',
             justifyContent: 'flex-end',
             padding: '10px',
           }}>
           <button className="login_button" onClick={() => setPanel(panel === 'all' ? 'create' : 'all')}>
             <span className="text_1">{panel === 'all' ? 'Create +' : 'All Trucks'}</span>
           </button>
         </div>
       </div> 
       </>
    </>
  ) : (
    <></>
  );
}

export default AdminPage;
