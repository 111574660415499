import {onValue, ref, remove, update} from 'firebase/database';
import React, {useEffect, useState} from 'react';
import {db} from '../../firebase/firebase';
import trush from '../../assets/svg/trush.png';
import copy from '../../assets/svg/copy.png';
import play from '../../assets/svg/play.png';
import pause from '../../assets/svg/pause.png';
import loadingImg from '../../assets/svg/loading.jpg';
import loadingAnimation from '../../assets/lotties/loadingAnimation';
import Lottie from 'lottie-react';
import moment from 'moment';

const calculateRoadDistance = async ({start, end}) => {
  // Create a DirectionsService instance
  const directionsService = new window.google.maps.DirectionsService();

  return new Promise((resolve, reject) => {
    // Request for directions
    directionsService.route(
      {
        origin: new window.google.maps.LatLng(start.lat, start.lng),
        destination: new window.google.maps.LatLng(end.lat, end.lng),
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          // Calculate total distance from all legs in the route
          const route = response.routes[0];
          let totalDistance = 0;

          route.legs.forEach(leg => {
            totalDistance += leg.distance.value; // Distance in meters
          });

          // Convert distance to kilometers
          const distanceInKm = totalDistance / 1000;

          // Resolve the promise with the calculated distance
          resolve(distanceInKm);
        } else {
          console.error('Directions request failed due to ' + status);
          // Reject the promise with an error message
          reject(new Error('Directions request failed'));
        }
      },
    );
  });
};
function AdminPanelLists({
  setSelectedPlace,
  selectedPlace,
  isFinish,
  currentPosition,
  end,
  speed,
  start,
  id,
  isPaused,
  setCurrentPosition,
  setPanel
}) {
  const [trucks, setTrucks] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pouseloading, setPouseloading] = useState(false);
  const [playloading, setPlayloading] = useState(false);
  const location = window.location.origin;

  useEffect(() => {
if(currentPosition && pouseloading){
  handleUpdatePouse()
  setPouseloading(false)
  setCurrentPosition()
}
if(currentPosition && playloading){
  handleUpdatePlay()
  setPlayloading(false)
  setCurrentPosition()
}
  }, [currentPosition, pouseloading, playloading]);

  useEffect(() => {
    onValue(ref(db), snapshot => {
      const data = snapshot.val();
      if (data !== null) {
        const trucksArray = Object.keys(data).map(key => ({
          id: key,
          ...data[key],
        }));
        setTrucks(trucksArray);
        setLoading(false);
      } else {
        setTrucks([]);
        setLoading(false);
      }
    });
  }, []);
  const handleDelete = () => {
    remove(ref(db, `/${selectedPlace.id}`));
    setSelectedPlace();
    setCurrentPosition()
  };
const handleUpdatePlay = async() =>{
  setPlayloading(true)
  const coordinates = await calculateRoadDistance({start: start, end: end});
  if(coordinates){
    const currentDate = moment(); // Get the current date and time
      const futureDate = currentDate.add(coordinates / speed, 'hours'); // Add timeInHours to the current date and time
      const coorectFutureDate = moment(futureDate).format(
        'dddd, MMMM Do YYYY, h:mm:ss A',
      );
      update(ref(db, `/${selectedPlace.id}`), {
        distance: coordinates,
        futureDate: coorectFutureDate,
        isPaused: false,
      });
      setSelectedPlace({
        ...selectedPlace,
        isPaused: false, // Update only the isPaused property
      });
      setShowButtons(false)
  }

}
  const handleUpdatePouse = async () => {
    setPouseloading(true)
    const coordinates = await calculateRoadDistance({start: start, end: end});
    if (coordinates) {
      update(ref(db, `/${selectedPlace.id}`), {
        coordinatesTo: end,
        distance: coordinates,
        futureDate: 'Stoped',
        isPaused: true,
        coordinatesFrom: start
      });
      setSelectedPlace({
        ...selectedPlace,
        isPaused: true,
      });
      setShowButtons(false)
    } 
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(`${location}/${selectedPlace.id}`)
      .then(() => {
        alert(`Truck width ${selectedPlace.id}, copyed!`);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          justifyContent: 'center',
          height: '300px',
          display: 'flex',
          marginTop: '100px',
        }}>
        <Lottie animationData={loadingAnimation} loop={true} />
      </div>
    );
  }

  return (
    <div>
      <div className="flex_just">
        <span className="title_1">Admin Panel</span>
        <div className="flex_just">
        {selectedPlace && showButtons &&(
          <>
          <div className='flex_column' style={{maxWidth: '400px', marginRight: '50px', marginLeft: '10px'}}>
          <div style={{}}>
               <span className="title_2 title_bold">Trucking ID: {id}</span>
             </div>
             <div style={{marginTop: '10px'}}>
               <span className="title_2 title_bold">Banner Title: {selectedPlace?.bannerTitle}</span>
             </div>
          </div>
          
          <div className="flex_just">
          <button className="edit_button" onClick={() => setPanel('edit')}>
             <span className="text_1" style={{fontSize: 14}}>{'Edit'}</span>
           </button>
            <button onClick={handleDelete} className="button_defoult">
              <img src={trush} style={{width: 30, height: 30}} alt=''/>
            </button>
            <button onClick={copyToClipboard} className="button_defoult">
              <img src={copy} style={{width: 30, height: 30}} alt=''/>
            </button>
            {!isFinish  || isPaused ? (
              
            <>
                {selectedPlace.futureDate !== 'Stoped' ? (
                  <>
                   <button
                onClick={() =>
                  handleUpdatePouse(
                    selectedPlace.futureDate !== 'Stoped' ? true : false,
                  )
                }
                className="button_defoult">
                  {pouseloading ? 
                  <img src={loadingImg} style={{width: 30, height: 30, borderRadius: 15}} alt=''/>
                  :
                  <img src={pause} style={{width: 30, height: 30}} alt=''/>
                  }
                  </button>
                  </>
                
                ) : (
                  <button
                  onClick={() => handleUpdatePlay()}
                  className="button_defoult">
                    {playloading ? 
                  <img src={loadingImg} style={{width: 30, height: 30, borderRadius: 15}} alt=''/>
                  :
                  <img src={play} style={{width: 30, height: 30}} alt=''/>
                  }
                
                  </button>
                )}
            </>
            ) : (
              <span
                style={{
                  color: 'greenyellow',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  paddingLeft: '5px',
                }}>
                Finished
              </span>
            )}
          </div>
          </>
        )}
        </div>
      </div>
      <div className="adding_box">
        {trucks.length === 0 ? (
          <div
            style={{
              height: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <span className="title_1">Empoty List</span>
          </div>
        ) : (
          <>
            <div className="truck_list_items titleContent">
              <div style={{width: '25%'}}>
                <span className="title_2 title_bold">Address From</span>
              </div>
              <div style={{width: '25%'}}>
                <span className="title_2 title_bold">Address To</span>
              </div>
              <div style={{width: '20%'}}>
                <span className="title_2 title_bold">Speed km/h</span>
              </div>
              <div style={{width: '40%'}}>
                <span className="title_2 title_bold">Future Date</span>
              </div>
            </div>
            {trucks
            .filter((elem) => elem.server === window.location.href)
            .map(elem => {
              const date1 = moment(
                elem?.futureDate,
                'dddd, MMMM Do YYYY, h:mm:ss A',
              );
              const date2 = moment(); // Current date and time  const differenceInSeconds = date1.diff(date2, 'seconds');
              const differenceInSeconds = date1.diff(date2, 'seconds');
              return (
                <div
                  key={elem.id}
                  className={
                    differenceInSeconds > 0
                      ? selectedPlace?.id === elem.id
                        ? 'truck_list_items_selected'
                        : 'truck_list_items'
                      : 'truck_list_items_finish'
                  }
                  onClick={() => {setSelectedPlace(elem); setShowButtons(true)}}>
                  <div style={{width: '25%'}}>
                    <span className="title_2">{elem.addressFrom}</span>
                  </div>
                  <div style={{width: '25%'}}>
                    <span className="title_2">{elem.addressTo}</span>
                  </div>
                  <div style={{width: '20%'}}>
                    <span className="title_2">{elem.speed} km/h</span>
                  </div>
                  <div style={{width: '40%'}}>
                    {elem.futureDate === 'Stoped' ? (
                  
                        <img src={pause} style={{width: 20, height: 20}} alt=''/>
                    ) : (
                      <span className="title_2">{elem.futureDate}</span>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default AdminPanelLists;
